




























































































































import { defineComponent, onMounted, reactive } from "@vue/composition-api";
import { AxiosInstance } from "axios";
import useAbstract from "@/use/abstract";
import printJS from "print-js";
import { v4 as uuid } from "uuid";

export default defineComponent({
  props: {
    abstract: {
      type: String,
      required: true,
      default: "",
    },
  },
  setup(props, { root, emit }) {
    const { getTypeName, getStatusName } = useAbstract({ root });

    const state = reactive({
      loading: false,
      loaded: false,
      success: false,
      error: false,
      abstract: {},
      deleteDialog: false,
    });

    const model = reactive({
      answers: [],
    });

    // Start fetch abstract

    const fetchData = () => {
      const axiosInstance = root.$store.getters[
        "api/getInstance"
      ] as AxiosInstance;

      state.loading = true;

      axiosInstance
        .get(`/abstract/${props.abstract}/system`)
        .then(({ data: { abstract } }) => {
          state.abstract = abstract;
        })
        .catch((error) => {
          console.log(error);
        })
        .finally(() => {
          state.loaded = true;
          state.loading = false;
        });
    };

    onMounted(fetchData);

    // End fetch abstract

    // Start get abstract file

    const getFile = (name: string) => {
      const axiosInstance = root.$store.getters[
        "api/getInstance"
      ] as AxiosInstance;

      axiosInstance
        .get(`file/${name}`, { responseType: "blob" })
        .then((response) => {
          const fileURL = window.URL.createObjectURL(new Blob([response.data]));
          const fileLink = document.createElement("a");

          fileLink.href = fileURL;
          fileLink.setAttribute("download", `${name}`);
          document.body.appendChild(fileLink);

          fileLink.click();
          fileLink.remove();
        })
        .catch((e) => console.log(e));
    };

    // End get abstract file

    const print = () => {
      printJS({
        type: "html",
        printable: "printAbstract",
        ignoreElements: ["no-print"],
        targetStyles: ["*"],
      });
    };

    const deleteItem = (id: string) => {
      const axiosInstance = root.$store.getters[
        "api/getInstance"
      ] as AxiosInstance;

      state.loading = true;
      axiosInstance
        .delete(`abstract/${id}`, {
          headers: {
            event: root.$route.params.id,
          },
        })
        .then(() => {
          state.deleteDialog = false;

          root.$store.commit("snackbar/PUSH_MESSAGE", {
            id: uuid(),
            color: "primary",
            message: root.$tc("layout.misc.deleteSuccess"),
          });
          emit("fetch");
          emit("close");
        })
        .catch(() => {
          root.$store.commit("snackbar/PUSH_MESSAGE", {
            id: uuid(),
            color: "error",
            message: root.$tc("layout.misc.deleteFailed"),
          });
        })
        .finally(() => (state.loading = false));
    };

    return {
      state,
      model,

      getTypeName,
      getStatusName,

      getFile,
      print,
      deleteItem,
    };
  },
});
